@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import './styles/material-icon.scss';
@import './styles/fonts.scss';
@import './styles/colors.scss';
@import './styles/custom-antd.scss';
@import './styles/scroll.scss';

/* Geral */
body {
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 0.9rem;
  background-color: $cm-color-white;
}

.container-fluid {
  padding: 12px;
  height: calc(100vh - 96px);
}

a {
  color: $cm-color-primary !important;
}

.e-underline:focus,
.e-underline:hover {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer;
}

.branding {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.branding img {
  width: 120px;
  margin-top: -7px;
  vertical-align: middle;
}

.col-left {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  padding: 50px;
  padding-bottom: 0;
  background-color: #fff;
}

.col-right {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 401px;
  background-image: url('/assets/img/bg_skate.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.bold700 {
  font-weight: 700 !important;
}

.errorFileTypeMessage {
  color: $cm-color-primary;
}

.slogan {
  color: $cm-color-primary;
  font-size: 1.8rem;
  line-height: 34px;
  margin-bottom: 2rem;
}

.color-primary {
  color: $cm-color-primary;
}

.bg-primary {
  background-color: $cm-color-primary;
}

.font-size-sm {
  font-size: 0.8rem;
}

.box-shadow-inner-top {
  box-shadow: inset 0 35px 30px -35px #9999994d !important;
}

.container-module {
  position: fixed;
  top: 77px;
  bottom: 0;
  right: 0;
  left: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1.5rem;
}

.container-module .row {
  padding-right: 1.1rem;
  padding-left: 1.1rem;
}

.text-warning-cc {
  color: #ffa146;
  font-size: 1.15rem;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.footer-text {
  font-size: small;
  text-align: center;
  flex: 1 1 auto;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 30px 16px 0;
  float: left;
}

.header-menu {
  line-height: 64px;
}

.content {
  border-left: 1px solid #ccdff2;
}

.downloadFilesList {
  background-color: #f1f1f1;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 5px;
}

.center-btn-download {
  margin-top: 16px;
}

.processing-consolidated-general .material-icons {
  color: #ffa146;
}

.cm-m-btn-files {
  margin-top: 10px;
}

/* End Geral */

/** bootstrap **/
.text-dark {
  color: #000000 !important;
}

.text-danger {
  color: #ab1732 !important;
}

.btn-link {
  color: $cm-color-primary !important;
}

.btn-link:hover,
.btn-link:active {
  color: #b91835 !important;
}

.btn-danger {
  background-color: $cm-color-primary;
  border-color: #dc3545;
}

.btn .material-icons {
  vertical-align: middle;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-header {
  padding: 1.5rem 2rem;
}

.tab-pane {
  padding: 2rem 0;
}

/* End bootstrap */

/* Componente UserInfo */
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  margin-top: 10px;
}

.user-info img {
  margin-right: 10px;
}

.user-info i {
  padding: 0px 4px;
  align-self: end;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 4px;
}

/* End Componente UserInfo */

/* Componente spreadsheet */
.cell-error-table {
  background: #ffe6eb !important;
  border-bottom: 1px solid #ab1732 !important;
}

.cell-success-table {
  background: #dfffe4 !important;
  border-bottom: 1px solid #035610 !important;
}

.modal-icon {
  font-size: 40px;
}

.modal-icon.success {
  color: #035610;
}

.modal-icon.error {
  color: $cm-color-primary;
}

.modal-icon.alert {
  color: #ffa146;
}

.modal-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.modal-text.success {
  color: #035610;
}

.modal-text.error {
  color: $cm-color-primary;
}

.modal-error-message {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 0;
  z-index: 11;
}

.error-border > nz-select-top-control {
  border-color: $cm-color-primary !important;
}

/* End Componente spreadsheet */

.cm-page-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $cm-color-dark;
  margin-bottom: 16px;
}

.cm-page-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $cm-color-primary;
  margin-bottom: 16px;
}

.cm-user-avatar {
  color: $cm-color-white;
  background-color: $cm-color-white-medium;
}

// não estava aplicando se inserido no css do componente
nz-spin > div.ant-spin-container.ng-star-inserted {
  height: 100% !important;
}

.ant-card.h-100 > .ant-card-body {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

@import './styles/media-queries/xs.scss';
@import './styles/media-queries/sm.scss';
@import './styles/media-queries/md.scss';
@import './styles/media-queries/lg.scss';
@import './styles/media-queries/xl.scss';
@import './styles/media-queries/xxl.scss';

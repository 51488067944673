@import './breakpoints.scss';

@media (min-width: 0px) {
  .ant-card-body {
    padding: 16px;
  }
  .container-fluid {
    padding: 0px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
}

$cm-color-white: #ffffff;
$cm-color-white-medium: #bdbdbd;
$cm-color-white-light: #f1f1f1;

$cm-color-brand-light: #ccdff2;
$cm-color-brand: #232738;
$cm-color-brand-dark: #120f0d;

$cm-color-dark: #232323;
$cm-color-dark-medium: #393939;
$cm-color-dark-light: #575757;

$cm-color-primary-light: #ffe6eb;
$cm-color-primary: #e42346;
$cm-color-primary-dark: #ab1732;

$cm-color-secondary-light: #dfffe4;
$cm-color-secondary: #23e446;
$cm-color-secondary-dark: #035610;

$cm-color-tertiary-light: #ffe8d4;
$cm-color-tertiary: #ffa146;
$cm-color-tertiary-dark: #9c5211;

@import './colors.scss';

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: $cm-color-white-light;
}

::-webkit-scrollbar-thumb {
  background-color: $cm-color-white-medium;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $cm-color-dark-light;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: $cm-color-dark-light;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-icons.woff2) format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-icons-outlined.woff2) format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-icons-round.woff2) format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-icons-sharp.woff2) format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/material-icons-twotone.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-sharp {
  font-family: 'Material Icons Sharp';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-20 {
  font-size: 20px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.material-icons-outlined.md-18 {
  font-size: 18px;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.material-icons-outlined.md-24 {
  font-size: 24px;
}
.material-icons-outlined.md-36 {
  font-size: 36px;
}
.material-icons-outlined.md-48 {
  font-size: 48px;
}

.material-icons-round.md-18 {
  font-size: 18px;
}
.material-icons-round.md-20 {
  font-size: 20px;
}
.material-icons-round.md-24 {
  font-size: 24px;
}
.material-icons-round.md-36 {
  font-size: 36px;
}
.material-icons-round.md-48 {
  font-size: 48px;
}

.material-icons-sharp.md-18 {
  font-size: 18px;
}
.material-icons-sharp.md-20 {
  font-size: 20px;
}
.material-icons-sharp.md-24 {
  font-size: 24px;
}
.material-icons-sharp.md-36 {
  font-size: 36px;
}
.material-icons-sharp.md-48 {
  font-size: 48px;
}

.material-icons-two-tone.md-18 {
  font-size: 18px;
}
.material-icons-two-tone.md-20 {
  font-size: 20px;
}
.material-icons-two-tone.md-24 {
  font-size: 24px;
}
.material-icons-two-tone.md-36 {
  font-size: 36px;
}
.material-icons-two-tone.md-48 {
  font-size: 48px;
}

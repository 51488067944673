@import './breakpoints.scss';

@media (min-width: $screen-xl-min) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}

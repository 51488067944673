/* Ant Design */
.ant-layout {
  background: none;
}

.ant-layout-header {
  padding: 0px 20px;
}

nz-select {
  margin: 0 2px 10px 0;
  width: 100%;
}

.ant-btn {
  border-radius: 4px;
}

.ant-btn:focus,
.ant-btn:hover {
  color: #000000d9;
  border-color: #000000d9;
}

.ant-btn:active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:focus {
  color: inherit;
  border-color: inherit;
}

.ant-spin-blur {
  clear: both;
  opacity: 0.3;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.cdk-overlay-container {
  z-index: 1600;
}

.ant-layout-header {
  background-color: #fff;
}

nz-header a {
  font-size: 0.7rem !important;
  opacity: 0.8;
  color: #555 !important;
  font-weight: bold;
}

nz-header a:hover,
nz-header a:active,
nz-header a:visited {
  color: #555 !important;
  opacity: 1;
}

nz-header .navbar-brand {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

nz-content {
  min-height: 280px;
}

.ant-spin-dot-item {
  background-color: $cm-color-primary !important;
}

.ant-spin {
  color: $cm-color-primary;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: $cm-color-primary;
  background: $cm-color-primary;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px #0000000b;
  font-weight: bold;
}

.ant-btn {
  font-size: 16px;
  height: 38px;
  padding: 6px 15px;
}

.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  border-color: #f35774;
  background: #f35774;
}

.ant-modal-header {
  padding: 9px 24px;
  border-bottom: 1px solid #d6d6d6;
  min-height: 56px;
}

.ant-modal-footer {
  border: 0;
  padding: 0 32px 32px 32px;
}

.ant-modal-close-x {
  width: 70px;
}

.ant-input-disabled,
.ant-input,
.ant-input-disabled,
.ant-input[disabled] {
  border-radius: 4px;
  border-color: $cm-color-brand-light !important;
  &:hover {
    border-color: $cm-color-brand-light !important;
  }
  &:focus {
    border-color: $cm-color-brand-light !important;
    box-shadow: none;
  }
}

.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
  color: $cm-color-primary;
}

/* End Ant Design */

html {
  --antd-wave-shadow-color: $cm-color-primary;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px $cm-color-primary-light;
}
.ant-radio-button-wrapper:hover {
  color: $cm-color-primary;
}
.ant-radio-checked::after {
  border: 1px solid $cm-color-primary;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: $cm-color-primary;
}

.ant-radio {
  & .ant-radio-input {
    &::selection {
      background: $cm-color-primary;
    }
  }

  & .ant-radio-inner {
    &::selection {
      background: $cm-color-primary;
    }
    &:after {
      background-color: $cm-color-primary;
    }
    &:hover {
      border-color: $cm-color-primary;
    }
  }
}

.ant-select-selector {
  border: 1px solid $cm-color-brand-light !important;
  &:hover {
    border-color: $cm-color-brand-light !important;
  }
  border-radius: 4px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $cm-color-brand-light !important;
  box-shadow: none;
}

.ant-picker {
  border: 1px solid $cm-color-brand-light !important;
  &:hover {
    border-color: $cm-color-brand-light !important;
  }
  border-radius: 4px;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $cm-color-brand-light;
}

.ant-card-bordered {
  border-radius: 4px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.ant-checkbox-inner {
  border: 1px solid $cm-color-dark-light;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $cm-color-primary;
  border-color: $cm-color-primary;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $cm-color-primary;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: $cm-color-primary;
}

.ant-btn-primary {
  background-color: $cm-color-primary;
  border-color: $cm-color-primary;
  color: $cm-color-white !important;
  &:hover {
    background-color: $cm-color-primary-dark;
    border-color: $cm-color-primary-dark !important;
  }
  &:focus {
    background-color: $cm-color-primary;
    border-color: $cm-color-primary;
  }
  &:active {
    background-color: $cm-color-primary-dark;
    border-color: $cm-color-primary-dark;
  }
  &:disabled {
    background-color: $cm-color-white-medium;
    border-color: $cm-color-white-medium;
    color: $cm-color-white;
  }
}

.ant-picker-cell {
  &.ant-picker-cell-in-view {
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: $cm-color-primary !important;
      }
    }
  }
}

.ant-picker-cell.ant-picker-cell-in-view {
  text-transform: capitalize;
}

.ant-picker.ng-valid .ant-picker-input {
  > input {
    text-transform: capitalize;
  }
}

.error-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
          button:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
}

.confirm-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
          button:nth-child(1) {
            > span {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: $cm-color-dark;
            }
            border: none;
            box-shadow: none;
            &:hover {
              background-color: $cm-color-white-light;
            }
            &:disabled {
              background-color: transparent;
              color: $cm-color-white-medium;
            }
          }
          button:nth-child(2) {
            > span {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: $cm-color-white;
            }
            background-color: $cm-color-primary;
            border: none;
            box-shadow: none;
            &:hover {
              background-color: $cm-color-primary-dark;
            }
            &:disabled {
              color: $cm-color-white;
              background-color: $cm-color-white-medium;
            }
          }
        }
      }
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $cm-color-white-light;
}

.ant-picker-header-view > button {
  color: $cm-color-dark;
  &:hover {
    color: $cm-color-dark;
    background-color: $cm-color-white-light;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $cm-color-primary !important;
}

.ant-tabs-tab:hover {
  color: $cm-color-primary !important;
}

.ant-tabs-ink-bar {
  background: $cm-color-primary;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: $cm-color-primary;
  border-top: 1px solid $cm-color-brand-light;
  &:hover {
    color: $cm-color-primary-dark;
  }
}

.ant-form-item-control {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-dropdown-menu-item-selected {
  color: $cm-color-primary;
  background-color: $cm-color-primary-light;
  > span {
    color: $cm-color-primary-dark;
  }
}

.ant-table-column-sorter-up.active {
  fill: $cm-color-primary;
  color: $cm-color-primary;
}

.ant-table-column-sorter-down.active {
  fill: $cm-color-primary;
  color: $cm-color-primary;
}

.ant-table-filter-trigger.active {
  fill: $cm-color-primary;
  color: $cm-color-primary;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-link {
    border: none;

    &:hover {
      color: $cm-color-primary-dark;
      background-color: $cm-color-primary-light;
    }
    > span {
      color: $cm-color-primary;
    }
  }
  .ant-btn-link > span,
  .ant-btn-primary > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
}

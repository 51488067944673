@font-face {
  font-family: 'Montserrat-Regular';
  src: url(../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(../assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url(../assets/fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}

@import './breakpoints.scss';

@media (min-width: $screen-xxl-min) {
  .ant-card-body {
    padding: 24px;
  }
  .container-fluid {
    padding: 12px;
  }
}
